<template>
    <div class="row mx-0 mb-1">
        <div class="col-md-6 px-0">
            <CustomDropDown :options="filterOpt" :initialValue="this.localInitialValue" @item-selected="handleItemSelected" :fieldName="this.fieldName" :selectedOpt="selectedOpt"></CustomDropDown>
        </div>
        <div class="col-md-6 px-0 ps-2">
            <CustomDropDown v-if="this.selectedFields && this.selectedFields[this.fieldName] && this.selectedFields[this.fieldName] == 'received_timerange'" :options="dateRangeOption" :initialValue="this.localFilterValue" @item-selected="handleDateTimeSelect" :fieldName="this.localFilterValue"></CustomDropDown>
            <CustomDropDown v-else-if="this.selectedFields && this.selectedFields[this.fieldName] && this.selectedFields[this.fieldName] == 'serverity'" :options="serverityOption" :initialValue="this.localFilterValue" @item-selected="handleSeveritySelect" :fieldName="this.localFilterValue"></CustomDropDown>
            <CustomDropDown v-else-if="this.selectedFields && this.selectedFields[this.fieldName] && this.selectedFields[this.fieldName] == 'life_stage'" :options="lifestageOption" :initialValue="this.localFilterValue" @item-selected="handleLifeStageSelect" :fieldName="this.localFilterValue"></CustomDropDown>
            <div class="position-relative" v-else>
                <input type="text" class="form-control" @input="handleInputChange" v-model="this.localFilterValue" @keyup.enter="submitForm" :readonly="this.selectedFields && !this.selectedFields[this.fieldName]">
                <i class="fa-solid fa-xmark" @click="this.removeItem(this.fieldName)"></i>
            </div>
        </div>
    </div>
</template>

<script>
import CustomDropDown from '../base/formFields/CustomDropDown.vue';

export default {
    data() {
        return {
            localInitialValue:this.initialValue,
            localFilterValue:this.initialValue,
            filterOpt:this.options,
            selectedFields:[],
            debounceTimeout: null, // to store the timeout ID
            dateRangeOption:[
                { value: "All", title: "All" },
                { value: "Today", title: "Today" },
                { value: "Yesterday", title: "Yesterday" },
                { value: "This Week", title: "This Week" },
                { value: "Last Week", title: "Last Week" },
                { value: "Last 7 Days", title: "Last 7 Days" },
                { value: "Last 30 Days", title: "Last 30 Days" },
                { value: "Last 90 Days", title: "Last 90 Days" },
                { value: "Last 180 Days", title: "Last 180 Days" },
                { value: "This Month", title: "This Month" },
                { value: "Last Month", title: "Last Month" },
                { value: "This Year", title: "This Year" },
            ],
            lifestageOption:[
                { value: "None", title: "None" },
                { value: "", title: "Not Set" },
                { value: "N", title: "Newborn: Birth to 28 days" },
                { value: "I", title: "Infant: 29 days to 2 years" },
                { value: "C", title: "Child: 2 years to 15 years" },
                { value: "T", title: "Adolescent: 16 to 17 years" },
                { value: "A", title: "Adult: 18 years or more" },
            ],
            serverityOption:[
                { value: "None", title: "None" },
                { value: "", title: "Not Set" },
                { value: "Mild", title: "Mild" },
                { value: "Moderate", title: "Moderate" },
                { value: "Severe", title: "Severe" },
            ],
        }
  },
  props: {
    options: {
        type: Array,
        required: true,
    },
    fieldName:{
        type: String,
        required: true,
    },
    initialValue:{
        type: String,
        required: true,  
    },
    selectedOpt:{
        type: Array,
        required: true, 
    }
  },
  components:{
    CustomDropDown
  },
  methods:{
    handleItemSelected({ fieldName, item }) {
        this.selectedFields[fieldName] = item.value
        item.field = fieldName
        this.$emit('array-updated', item);
        if (item.value == 'received_timerange') {
            this.localFilterValue = 'All'
        } else if(item.value == 'serverity' || item.value == 'life_stage') {
            this.localFilterValue = 'Not Set'
        } else {
            this.localFilterValue = ''
        }
        this.$emit('text-updated', {'field':this.fieldName, 'value':''});

    },
    handleDateTimeSelect({ fieldName, item }) {
        console.log(fieldName);
        this.$emit('text-updated', {'field':this.fieldName, 'value':item.value});
    },
    handleSeveritySelect({ fieldName, item }) {
        if(item.value == 'None') {
            this.removeItem(fieldName)
            this.submitForm()
        } else {
            this.$emit('text-updated', {'field':this.fieldName, 'value':item.value});
        }
    },
    handleLifeStageSelect({ fieldName, item }) {
        if(item.value == 'None') {
            this.removeItem(fieldName)
            this.submitForm()
        } else {
            this.$emit('text-updated', {'field':this.fieldName, 'value':item.value});
        }
    },
    handleInputChange(event){
        const newValue = event.target.value;
        this.$emit('text-updated', {'field':this.fieldName, 'value':newValue});
        
        // Clear the previous timeout if the user keeps typing
        clearTimeout(this.debounceTimeout);

        // Set a new timeout
        this.debounceTimeout = setTimeout(() => {
            this.submitForm();
        }, 1000); // 1 second delay

    },
    removeItem(currentIndex){
        this.localFilterValue = ''
        if (this.selectedOpt && this.selectedOpt[currentIndex] && this.selectedOpt[currentIndex]['field'] == 'received_timerange') {
            this.localFilterValue = 'All'
        }

        this.selectedFields.forEach((value, index) => {
            if(value == 'life_stage' || value == 'serverity') {
                currentIndex = index;
                const remainingOptions = this.options.filter(item => item.value !== value);
                this.$emit('remove-item', remainingOptions);
            } else {
                if (this.selectedFields[currentIndex]) {
                    const remainingOptions = this.options.filter(item => item.value !== this.selectedOpt[currentIndex]['field']);
                    this.$emit('remove-item', remainingOptions);
                } else {
                    this.$emit('remove-empty-item', currentIndex);
                }
            }
        });

        
    },
    submitForm(){
        this.$emit('request-filter');
    },
    
  },
  created(){
    if (this.selectedOpt && this.selectedOpt[this.fieldName] && this.selectedOpt[this.fieldName]['field'] == 'received_timerange') {
        this.localFilterValue = 'All'
    }
    this.filterOpt =this.options.filter(option => 
      !this.selectedOpt.some(selected => selected.field === option.value)
    );

    if (this.selectedOpt[this.fieldName] && this.selectedOpt[this.fieldName]['field']) {
        const foundObject = this.options.find(item => item.value === this.selectedOpt[this.fieldName]['field']);
        if (foundObject) {
            this.localInitialValue=foundObject.title
            let obj = {value: this.selectedOpt[this.fieldName]['field'], title: foundObject.title, field: this.fieldName}
            this.handleItemSelected({ fieldName: this.fieldName, item: obj });
            this.$emit('text-updated', {'field':this.fieldName, 'value':this.selectedOpt[this.fieldName]['value']});
        }
    }

    if (this.selectedOpt[this.fieldName] && this.selectedOpt[this.fieldName]['value']) {
        this.localFilterValue=this.selectedOpt[this.fieldName]['value']
        if(this.selectedOpt[this.fieldName]['field'] == 'life_stage') {
            const data = this.lifestageOption.find(item => item.value === this.selectedOpt[this.fieldName]['value']);
            this.localFilterValue=data.title;
        }
    }

  },

}

</script>