<template>
    <div class="search_drop_box" :class="{'advance-filter-optn':this.loopCount.length>4}">
        <div v-for="index in loopCount" :key="index">
            <CustomFilterEle :key="elementKey" :options="options" :fieldName="index" :initialValue="initialValue" @array-updated="handleArrayUpdate" @text-updated="handleTextUpdate" @remove-item="handleRemoveFilEle" @remove-empty-item="handleRemoveUnselectedOpt" :selectedOpt="selectedOpt" @request-filter="refreshData"></CustomFilterEle>
        </div>
        <div @click="addFilterEle" class="text-center plus_btn" v-if="selectedOpt.length < options.length ">+</div>
    </div>
</template>

<script>
import CustomFilterEle from '../base/CustomFilterEle.vue';

export default {
    data() {
        return {
            componetsRenderCnt: 1, // The number of times you want to loop
            selectedFieldNames:[],
            selectectedFields:[],
            elementKey:0
        }
  },
  props: {
    options: {
        type: Array,
        required: true,
    },
    fieldName:{
        type: String,
        required: true,
    },
    initialValue:{
        type: String,
        required: true,  
    },
    selectedOpt:{
      type: Array,
      required: true, 
    }
  },
  components:{
    CustomFilterEle
  },
  methods:{
    addFilterEle(){
        this.componetsRenderCnt++
    },
    handleArrayUpdate(arrayData) {
      if (this.selectectedFields[arrayData.field]) {
        this.selectectedFields[arrayData.field] = arrayData.value;
      } else {
        this.selectectedFields[arrayData.field] = ''
        this.selectectedFields[arrayData.field] = arrayData.value
      }

      if (this.selectedFieldNames[arrayData.field]) {
        this.selectedFieldNames[arrayData.field]['field'] = arrayData.value
      } else {
        this.selectedFieldNames[arrayData.field] = []
        this.selectedFieldNames[arrayData.field]['field'] = arrayData.value
      }
      const cleanedFieldNames = this.selectedFieldNames.filter(item => item !== null);
      this.$emit('custom-filter', cleanedFieldNames);

    },
    handleTextUpdate(value){
      if (this.selectedFieldNames[value.field]) {
        this.selectedFieldNames[value.field]['value'] = value.value
      } else {
        this.selectedFieldNames[value.field] = []
        this.selectedFieldNames[value.field]['value'] = value.value
      }

      const cleanedFieldNames = this.selectedFieldNames.filter(item => item !== null);
      this.$emit('custom-filter', cleanedFieldNames);

    },
    handleRemoveFilEle(value){
      const valuesList = value.map(option => option.value);
      const matchedRecord = this.selectedFieldNames.filter(record => valuesList.includes(record.field));
      this.selectedFieldNames = matchedRecord
      this.componetsRenderCnt--;

      const cleanedFieldNames = this.selectedFieldNames.filter(item => item !== null);
      this.$emit('custom-filter', cleanedFieldNames);
      
      this.elementKey += 1

    },
    handleRemoveUnselectedOpt(fieldIndex){
      this.selectectedFields.splice(fieldIndex, 1);  
      this.selectedFieldNames.splice(fieldIndex, 1);      
      this.componetsRenderCnt--;
      this.elementKey += 1


    },
    refreshData(){
      this.$emit('refresh-data');
    }
  },
  computed: {
    loopCount() {
      // Create an array with the desired length
      return Array.from({ length: this.componetsRenderCnt }, (_, index) => index);
    }
  },
  created() {
    if (this.selectedOpt.length > 0) {
        this.componetsRenderCnt  = this.selectedOpt.length
    }
  }
}
</script>
